<template>
  <div class="refundList page-gray">
    <van-tabs
        v-model="active"
        sticky
        swipeable
        :line-width="15"
        :line-height="4">
      <van-tab title="处理中" name="0">
        <after-sales-list v-if="active==0" />
      </van-tab>
      <van-tab title="已完成" name="1">
        <completed-list v-if="active==1"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import afterSalesList from './list.vue'
import completedList from "./completedList.vue";
export default {
  components:{afterSalesList,completedList},
  name: "userAudit",
  data() {
    return {
      active: '0'
    }
  }
}
</script>



<style scoped lang="less">

</style>
